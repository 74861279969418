const numberFormatter = (number) => {
  if (number === null || number === undefined) return '-'

  // Удаляем пробелы, запятые, проценты
  const numberWithoutSpaces = number.toString().replace(/[\s,%]/g, '')

  // Если число = не число возвращаем '-'
  if (isNaN(Number(numberWithoutSpaces))) return '-'

  const num = Number(numberWithoutSpaces)
  const hundredths = num * 100
  let numberRounded

  // Округляем до сотых
  if (hundredths > 0 && hundredths < 1) {
    // Если от 0 до 1
    numberRounded = Math.ceil(num * 100) / 100
  } else if (hundredths < 0 && hundredths > -1) {
    // Если от 0 до -1
    numberRounded = -Math.ceil(Math.abs(num * 100)) / 100
  } else {
    numberRounded = Math.round(num * 100) / 100
  }

  // Проверяем, является ли число целым
  const isInteger = Number.isInteger(numberRounded)
  const numberFormatted = isInteger ? numberRounded.toString() : numberRounded.toFixed(2)

  // Делим на две части: целая и дробная части
  const parts = numberFormatted.split('.')

  // Вставляем запятые между тысячами
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return parts.join('.')
}

export default numberFormatter
