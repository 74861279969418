import PropTypes from 'prop-types'
import cns from 'classnames'

import {
  discount,
  periods,
} from '@components/pricingSwitchers/period/periodData'
import useFormatMessage from '@utils/useFormatMessage'

import IconPricingArrow from '@icons/pricingArrow.svg'

import styles from './period.module.scss'

function Period({ selectedPeriod, setSelectedPeriod }) {
  const t = useFormatMessage()

  const handleClick = period => () => {
    setSelectedPeriod(period)
  }

  return (
    <div className={styles.root}>
      <div className={styles.actions}>
        {periods?.map((period, i) => (
          <button
            className={cns(
              styles.button,
              period !== selectedPeriod && 'button--ghost',
              period === selectedPeriod && 'button--secondary',
              'button button--small'
            )}
            onClick={handleClick(period)}
            key={i}
          >
            {t(`pricing.periods.${period}`)}
          </button>
        ))}
      </div>

        <div className={styles.discount}>
          <div className={styles.discount__text}>
            {t(`pricing.periods.discount`)}

            {` ${discount}%`}
          </div>

          <IconPricingArrow className={styles.discount__arrow} />
        </div>
    </div>
  )
}

Period.propTypes = {
  selectedPeriod: PropTypes.string,
  setSelectedPeriod: PropTypes.func,
}

export default Period
